img {max-width: 100% ;} 

/* disable css animations */
.touch .animated {
	-webkit-animation-duration: 0;
	animation-duration: 0;
	-webkit-animation-delay: 0;
	animation-delay: 0;
} 
/* disable background-size:cover */
.touch #container_header, .touch #container_top2_modules, .touch #container_bottom1_modules, .touch #container_bottom2_modules {
	-webkit-background-size: 	auto !important;
	-moz-background-size: 		auto !important;
	-o-background-size: 		auto !important;
	background-size: 			auto !important;
	background-repeat: repeat;
	background-attachment: scroll;
}

/* #Tablet (Landscape)
================================================== */

@media only screen and (max-width: 1024px) {

	.wrapper960 {
		width:100% !important;
	}
	.wrapper960, .content_background, #header {
		margin: 0 auto;
	}

}

/* #Tablet (Portrait)
================================================== */
    
@media only screen and (max-width: 959px) {

}

/* #Mobile (Landscape)
================================================== */

@media only screen and (max-width: 767px) {

	.backgrounds .sidecol_a, .backgrounds .sidecol_b {display:none;}
	#content_remainder {
		width: 100% !important;
	}
	.sidecol_a, .sidecol_b, .items-row .item, .top-1, .top-2, .top-3, .contenttop, .contentbottom, .bottom-1, .bottom-2, .base-1, .base-2 {
		width:100% !important;
	}
	.module:first-of-type .module_surround {
	    padding-top: 30px;
	}
	.module .module_surround, .module_menu .module_surround {
		padding: 0 35px 30px;
	}
	.row-fluid [class*="span"] {margin-left:0;}

	.logo {
	    padding: 15px;
	}
	h1.logo-text a {
		font-size: 26px;
	}

}

/*  #Mobile (Portrait)
================================================== */

@media only screen and (max-width: 440px) {

	#content_remainder, .items-row .item {
	    width: 100% !important;
	}
	.row-fluid [class*="span"] {margin-left:0;}
	#container_main .wrapper960 {
	    margin-top: 0px;
	}
	.slidesjs-previous.slidesjs-navigation, .slidesjs-next.slidesjs-navigation {
		display: none;
	}
	.layerslideshow .info {
		display: none;
	}

}


